@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap" rel="stylesheet");
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

.ant-notification-notice {
    background: #FCFCFD !important;
    /* Gray/300 */
    box-shadow: none !important;
    border: 1px solid #D0D5DD !important;
    border-radius: 12px !important;
    padding: 16px !important;
}

.ant-notification-notice-close {
    position: static !important;
    font-weight: 600 !important;
    text-align: right;
    font-size: 14px !important;
    font-family: 'Inter', sans-serif;
    color: #E9532F !important;
}

.ant-notification-notice-message {
    font-weight: 600;
    font-size: 14px !important;
    font-family: 'Inter', sans-serif;
    padding-right: 0 !important;
    color: #344054;
}

@media (max-width: 415px) {
    .ant-notification-notice-message {

        font-size: 12px !important;
    }
}

@media (max-width: 577px) {
    body {

    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mons-500 {
    font-family: 'Inter', sans-serif;
    font-weight: 500 !important;
}

.mons-600 {
    font-family: 'Inter', sans-serif;
    font-weight: 600 !important;
}

.mons-700 {
    font-family: 'Inter', sans-serif;
    font-weight: 700 !important;
}

.logo-header {
    background-image: url(/static/media/logo.55c3a08b.svg);
}

.background-icon {
    background-image: url("/static/media/Group icon.172c3469.svg")
}

.background-lights {
    background-image: url("/static/media/Group lights.af14f660.svg")
}

.background-phone {
    background-image: url(/static/media/IconPhone.88b0bdab.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 72px;
    height: 72px;
    margin: auto;
}

.MuiButton-root.Mui-disabled {
    opacity: .5;
}

.confirm-inputs {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.input-otp {
    width: 80px !important;
    text-align: center;
    vertical-align: center;
    height: 80px !important;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    -webkit-box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    margin-right: 8px;
    font-weight: 500;
    font-size: 40px;
    color: #101828;
    padding: 0;
}

@media (max-width: 576px) {
    .confirm-inputs .input-otp {
        width: 100% !important;
        height: 53px !important;
        font-weight: 500;
        font-size: 25px;
        color: #101828;
    }
}

.tooltip-custom {
    border-radius: 8px !important;
    opacity: 1 !important;
}

.input-placeholder-style input {
    color: #D0D5DD !important;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.confirm-inputs .input-otp::-webkit-input-placeholder {
    color: #D0D5DD;
}

.confirm-inputs .input-otp:-ms-input-placeholder {
    color: #D0D5DD;
}

.confirm-inputs .input-otp::placeholder {
    color: #D0D5DD;
}

.confirm-inputs .input-otp:nth-child(4) {
    margin-left: 6px;
}

.text-left {
    text-align: left !important;
}

.confirm__wrapper {
    position: relative;
}

.separator {
    font-weight: 500;
    font-size: 48px;
    color: #D0D5DD;
    position: absolute;
    top: 47%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.tooltip-icon {
    position: absolute;
    z-index: 999;
    right: 15px;
    top: 20px;
}

.ant-tooltip-inner {
    border-radius: 8px !important;
}

@media (max-width: 576px) {


    .confirm-inputs .input-otp {
        width: 14% !important;
        margin-right: 6px;
    }

    .confirm-inputs .input-otp:last-child {
        margin-right: 0;
    }


    .separator {

        font-size: 32px !important;
    }

    .confirm-inputs div:first-child {
        -webkit-justify-content: center !important;
                justify-content: center !important;
    }
}

@media (min-width: 577px) {
    .form_position > div:first-child {
        position: absolute;
        top: 44%;
        right: -26%;
    }

    .form_position .error-mes {
        padding-top: 30px;
    }
}

.confirm-inputs .input-otp:focus {
    background-clip: padding-box;
    box-shadow: 0px 0px 0px 4px #FEE4E2;
    border-radius: 8px;
    outline: none !important;
    border: 2px solid #E9532F
}

.confirm-inputs .input-otp:valid {
    border-width: 2px !important;
}

.error-mes {
    text-align: center;
    font-size: 14px;
    color: #F04438;
}

.valid-error {
    border: 2px solid #FDA29B !important;
    border-radius: 8px;
}

.valid-error:focus {
    background-clip: padding-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2 !important;
}


.valid-errors {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2 !important;
    border: 2px solid #FDA29B !important;
}

.valid-errors-otp {
    border: 2px solid #FDA29B !important;
    color: #E9532F !important;
}


.separator__error {
    color: #E9532F;
}

.valid-errors-otp input:focus {
    background-clip: padding-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2 !important;
}


.confirm-inputs .input-otp:last-child {
    margin-right: 0;
}

@media (max-width: 576px) {
    .background-phone {
        margin: 40px auto 0;
    }
}


::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    display: -webkit-flex;
    display: flex;
}

/* Track */
::-webkit-scrollbar-track {

}

/* the slides */

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #E9532F;
}


.confirm-inputs .input-otp:nth-child(3) {
    margin-right: 20px !important;
}

.confirm-inputs .input-otp:nth-child(4) {
    margin-left: 20px !important;
}
